export const SCREEN_BREAKPOINTS = {
	mobileSmall: 375,
	iphoneXs: 414,
	mobileMedium: 600,
	mobileLarge: 768,
	tabletSmall: 800,
	tabletMedium: 1024,
	tabletLarge: 1280,
	notebook: 1366,
	desktopSmall: 1440,
	desktopMedium: 1820,
	desktopLarge: 1920
};
export const DEFAULT_LIMIT = 20;
