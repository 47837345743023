import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const NavSimple = ({ data }) => (
	<ul className={styles.nav}>
		{_(data).map((item) => (
			<li className={styles.item} key={item.url}>
				<a
					href={item.url}
					className={styles.link}
					target={item.target}
					rel={item.rel}
				>
					{item.title}
				</a>
			</li>
		))}
	</ul>
);

NavSimple.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
			target: PropTypes.string,
			rel: PropTypes.string
		})
	).isRequired
};

export default NavSimple;
