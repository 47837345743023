import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ToTopButton from 'components/ToTopButton';

import styles from './styles.module.scss';

const FabContainer = ({ parentContainerRef }) => {
	const containerRef = useRef(null);
	const [position, setPosition] = useState(null);
	const [show, setShow] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			const currentPosition = window.scrollY || window.pageYOffset;
			const viewportHeight = window.innerHeight;
			const {
				offsetTop: parentTopPosition = 0,
				offsetHeight: parentHeight = 0
			} = parentContainerRef.current || {};

			setShow(currentPosition >= viewportHeight);
			setPosition(
				currentPosition + viewportHeight >= parentTopPosition + parentHeight
					? 'bottom'
					: null
			);
		};

		onScroll();

		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	const fabContainerCx = cx(
		styles.FabContainer,
		styles[`FabContainer_position_${position}`],
		{
			[styles.FabContainer_show]: show
		},
		'pvi-no-styles'
	);

	return (
		<div className={fabContainerCx} ref={containerRef}>
			<ToTopButton />
		</div>
	);
};

FabContainer.propTypes = {
	parentContainerRef: PropTypes.shape({
		current: PropTypes.object
	}).isRequired
};

export default FabContainer;
