import React from 'react';
import PropTypes from 'prop-types';

import Pseudolink from 'components/Pseudolink';

import styles from './styles.module.scss';

const HelpList = ({ showFaqModal }) => (
	<ul className={styles.list}>
		<li>
			E-mail:{' '}
			<a href="mailto:cultrf@mkrf.ru" className={styles.link}>
				cultrf@mkrf.ru
			</a>
		</li>
		<li>
			<Pseudolink title="Обратная связь" onClick={showFaqModal} />
		</li>
		<li className={styles.link}>
			Нашли опечатку?&nbsp;
			<kbd className={styles.tip}>Ctrl+Enter</kbd>
		</li>
	</ul>
);

HelpList.propTypes = {
	showFaqModal: PropTypes.func.isRequired
};

export default HelpList;
