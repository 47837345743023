import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';

import styles from './styles.module.scss';

const animationDurationHash = {
	fade: 150,
	slide: 300,
	verticalSlide: 300,
	slideDown: 150
};

const Animation = ({ animation, children, show, ...props }) => (
	<CSSTransition
		classNames={{
			enter: cx(styles[animation], styles.enter),
			enterActive: cx(styles[animation], styles.enterActive),
			enterDone: cx(styles[animation], styles.enterDone),
			exitActive: cx(styles[animation], styles.exitActive),
			exitDone: cx(styles[animation], styles.exitDone)
		}}
		in={show}
		timeout={animationDurationHash[animation]}
		unmountOnExit
		{...props}
	>
		{children}
	</CSSTransition>
);

Animation.propTypes = {
	animation: PropTypes.oneOf(_(animationDurationHash).keys()),
	children: PropTypes.node.isRequired,
	show: PropTypes.bool
};

Animation.defaultProps = {
	animation: 'fade',
	show: false
};

export default Animation;
