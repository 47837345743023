import React, { memo, useCallback } from 'react';
import { anime } from 'react-anime';
import cx from 'classnames';

import Icon from 'components/Icon';

import useGlobalElementRef from 'hooks/useGlobalElementRef';

import styles from './styles.module.scss';

const ToTopButton = () => {
	const buttonRef = useGlobalElementRef('toTopButton');

	const onClick = useCallback(() => {
		const scrollElement =
			window.document.scrollingElement ||
			window.document.documentElement ||
			window.document.body;

		anime({
			targets: scrollElement,
			scrollTop: 0,
			duration: 500,
			easing: 'easeInOutQuad'
		});
	}, []);

	return (
		<div ref={buttonRef} className="pvi-no-styles">
			<button
				className={cx(styles.ToTopButton, 'pvi-no-styles')}
				onClick={onClick}
				type="button"
				title="Наверх"
			>
				<Icon icon="arrow-top" className={styles.ToTopButton__IconTop} />
			</button>
		</div>
	);
};

export default memo(ToTopButton);
