/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import _ from 'underscore';
import React, { memo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import LinkList from 'components/LinkList';

import { NavigationItemPropType } from 'propTypes/common';

import styles from './styles.module.scss';

const NavigationItem = ({
	isOpen,
	item,
	onMouseEnterItem,
	onMouseLeaveItem,
	onClickButton,
	type,
	activeUrl,
	theme,
	className,
	linkClassName
}) => {
	const ref = useRef(null);
	const { _id, title, url, children: list } = item;
	const hasSubNav = !_.isEmpty(list);
	const itemCx = cx(
		styles.item,
		{
			[styles.open]: isOpen,
			[styles.active]: activeUrl && activeUrl.startsWith(url)
		},
		styles[`type_${type}`],
		styles[`theme_${theme}`],
		// 'bvi-speech',
		className
	);

	const onMouseEnterHandle = useCallback(() => {
		onMouseEnterItem(_id, ref);
	}, [onMouseEnterItem, _id, ref]);

	const onMouseLeaveHandle = useCallback(() => {
		onMouseLeaveItem(_id, ref);
	}, [onMouseLeaveItem, _id, ref]);

	const onClickHandle = useCallback(() => {
		onClickButton(_id, ref);
	}, [onClickButton, _id, ref]);

	return (
		<li
			ref={ref}
			className={itemCx}
			onMouseEnter={onMouseEnterHandle}
			onMouseLeave={onMouseLeaveHandle}
		>
			{url && !hasSubNav ? (
				<a href={url} className={cx(styles.link, linkClassName)}>
					<div className={styles.title}>{title}</div>
				</a>
			) : (
				<>
					<button
						className={cx(styles.button, linkClassName)}
						type="button"
						onClick={onClickHandle}
					>
						<span className={styles.title}>{title}</span>
					</button>
					{hasSubNav && (
						<div className={styles.linkList}>
							<LinkList list={list} type={type} />
						</div>
					)}
				</>
			)}
		</li>
	);
};

NavigationItem.propTypes = {
	item: NavigationItemPropType.isRequired,
	isOpen: PropTypes.bool,
	onMouseEnterItem: PropTypes.func,
	onMouseLeaveItem: PropTypes.func,
	onClickButton: PropTypes.func,
	type: PropTypes.oneOf(['mobile']),
	theme: PropTypes.string,
	activeUrl: PropTypes.string,
	className: PropTypes.string,
	linkClassName: PropTypes.string
};

NavigationItem.defaultProps = {
	isOpen: false,
	onMouseEnterItem: _.noop,
	onMouseLeaveItem: _.noop,
	onClickButton: _.noop
};

export default memo(NavigationItem);
