import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { usePopper } from 'react-popper';

import { createPortal } from 'react-dom';
import { GlobalElementsContext } from 'contexts/GlobalElementsContext';

import { useIsMounted } from 'hooks/useIsMounted';
import PopupContent from '../Content';

import styles from './styles.module.scss';

const PopupWrapper = ({
	element,
	children,
	options,
	onClickOutside,
	placement,
	width,
	...props
}) => {
	const [popperElement, setPopperElement] = useState(null);
	const [getGlobalElements] = useContext(GlobalElementsContext);
	const [layoutElement] = getGlobalElements('layout');
	const mounted = useIsMounted();

	const { styles: popupStyles, attributes } = usePopper(
		element,
		popperElement,
		{
			placement,
			strategy: 'fixed',
			...options,
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [0, 10]
					}
				},
				...(options.modifiers || [])
			]
		}
	);

	useEffect(() => {
		let cleanup;

		if (onClickOutside) {
			const onClick = (event) => {
				if (element && !element.contains(event.target)) {
					onClickOutside();
				}
			};

			layoutElement.addEventListener('click', onClick);

			cleanup = () => {
				layoutElement.removeEventListener('click', onClick);
			};
		}

		return cleanup;
	}, [onClickOutside, getGlobalElements]);

	return mounted
		? createPortal(
				<div
					className={cx(styles.popup, { [styles[`width_${width}`]]: width })}
					ref={setPopperElement}
					style={popupStyles.popper}
					{...attributes.popper}
				>
					<PopupContent {...props}>{children}</PopupContent>
				</div>,
				document.querySelector('#portal')
		  )
		: null;
};

PopupWrapper.propTypes = {
	element: PropTypes.object,
	children: PropTypes.node,
	options: PropTypes.object,
	onClickOutside: PropTypes.func,
	placement: PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom']),
	theme: PropTypes.oneOf(['widget']),
	width: PropTypes.oneOf(['widgetMedium', 'mobileFull'])
};

PopupWrapper.defaultProps = {
	options: {},
	placement: 'bottom-end'
};

export default PopupWrapper;
