import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import styles from './styles.module.scss';

const Pseudolink = ({ title, theme, isBold, ...props }) => {
	const pseudolinkCx = cx(styles.pseudolink, styles[`theme_${theme}`], {
		[styles.bold]: isBold
	});

	return (
		<button type="button" className={pseudolinkCx} {...props}>
			{title}
		</button>
	);
};

Pseudolink.propTypes = {
	title: PropTypes.string.isRequired,
	theme: PropTypes.oneOf(['colored']),
	onClick: PropTypes.func,
	isBold: PropTypes.bool
};

Pseudolink.defaultProps = {
	onClick: _.noop,
	isBold: false
};

export default Pseudolink;
