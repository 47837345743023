import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { iconNames } from 'utils/helpers/icons';

import styles from './styles.module.scss';

const Icon = ({ icon, className, useClassName, ...props }) => {
	const iconCx = cx(styles.icon, className);

	return (
		<svg className={iconCx} {...props} data-cy="icon">
			<use
				xmlnsXlink="http://www.w3.org/1999/xlink"
				xlinkHref={`#icon-${icon}`}
				className={useClassName}
			/>
		</svg>
	);
};

Icon.propTypes = {
	icon: PropTypes.oneOf(iconNames).isRequired,
	className: PropTypes.string,
	useClassName: PropTypes.string
};

export default Icon;
