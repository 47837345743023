import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const AuthButtonContainer = ({ render }) => {
	const { currentUser } = useSelector((state) => state.currentUser);

	return render(currentUser);
};

AuthButtonContainer.propTypes = {
	render: PropTypes.func.isRequired
};

export default AuthButtonContainer;
