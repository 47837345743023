import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';
import { systemMessageActions } from 'ducks/systemMessage';

const SystemMessage = dynamic(() => import('components/SystemMessage'), {
	ssr: false
});

const SystemMessageContainer = () => {
	const dispatch = useDispatch();
	const { message } = useSelector((state) => state.systemMessage);

	const [isOpen, setIsOpen] = useState(Boolean(message));

	useEffect(() => {
		setIsOpen(Boolean(message));
	}, [message]);

	const onCloseMessage = useCallback(() => setIsOpen(false), []);

	const onAfterClose = useCallback(() => {
		dispatch(systemMessageActions.removeMessage());
	}, []);

	return isOpen ? (
		<SystemMessage
			isOpen={isOpen}
			message={message}
			onCloseMessage={onCloseMessage}
			onAfterClose={onAfterClose}
		/>
	) : null;
};

export default SystemMessageContainer;
