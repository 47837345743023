import React from 'react';
import PropTypes from 'prop-types';

import AdditionalAboutInfo from '../AdditionalAboutInfo';
import HelpList from '../HelpList';

import Block from './Block';

import styles from './styles.module.scss';

const Sidebar = ({ showFaqModal }) => (
	<div className={styles.sidebar}>
		<Block title="Контакты">
			<HelpList showFaqModal={showFaqModal} />
		</Block>
		<Block title="Материалы">
			<AdditionalAboutInfo />
		</Block>
	</div>
);

Sidebar.propTypes = {
	showFaqModal: PropTypes.func.isRequired
};

export default Sidebar;
