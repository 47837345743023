export const socialsList = [
	{
		title: 'Вконтакте',
		url: 'https://vk.com/cultrf',
		icon: 'vkontakte'
	},
	{
		title: 'Одноклассники',
		url: 'https://ok.ru/cultrf',
		icon: 'odnoklassniki'
	},
	{
		title: 'Telegram',
		url: 'https://t.me/cultrf',
		icon: 'telegram'
	},
	{
		title: 'Яндекс.Мессенджер',
		url: 'https://yandex.ru/chat/#/join/0b5f4f63-749c-46e6-a2b9-f14f6a8c435d',
		icon: 'yaMessenger'
	},
	{
		title: 'Подкасты',
		url: '/s/podcasts',
		icon: 'podcast'
	},
	{
		title: 'Яндекс.Дзен',
		url: 'https://zen.yandex.ru/portalculture',
		icon: 'yaDzen'
	}
];

export const authSocials = [
	// {
	// 	name: 'esia',
	// 	title: 'Госуслуги',
	// 	href: '/social/esia'
	// },
	{
		name: 'pro',
		title: 'Pro.Культура.Рф',
		href: '/social/eipsk'
	},
	{
		name: 'vkontakte',
		title: 'Вконтакте',
		href: '/social/vk'
	},
	{
		name: 'odnoklassniki',
		title: 'Одноклассники',
		href: '/social/ok'
	}
];
