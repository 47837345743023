import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'components/Icon';

import styles from './styles.module.scss';

const PopupContent = ({
	children,
	isCloseButton,
	title,
	onClose,
	padding,
	theme
}) => {
	const popupContentCx = cx(
		styles.content,
		styles[`padding_${padding}`],
		styles[`theme_${theme}`]
	);

	return (
		<div className={popupContentCx}>
			{(title || isCloseButton) && (
				<div className={styles.header}>
					{title && <span className={styles.title}>{title}</span>}

					{isCloseButton && (
						<button
							type="button"
							className={styles.closeButton}
							title="Закрыть"
							onClick={onClose}
						>
							<Icon icon="close" className={styles.closeIcon} />
						</button>
					)}
				</div>
			)}
			<div>{children}</div>
		</div>
	);
};

PopupContent.propTypes = {
	children: PropTypes.node,
	isCloseButton: PropTypes.bool,
	title: PropTypes.string,
	onClose: PropTypes.func,
	padding: PropTypes.oneOf(['bottomIncrease']),
	theme: PropTypes.oneOf(['widget'])
};

PopupContent.defaultProps = {
	isCloseButton: false,
	onClose: _.noop
};

export default PopupContent;
