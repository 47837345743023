import React from 'react';

import styles from './styles.module.scss';

const UserMenu = () => (
	<div className={styles.menu}>
		<a className={styles.item} href="/profile">
			Профиль
		</a>
		<a className={styles.item} href="/auth/logout">
			Выход
		</a>
	</div>
);

export default UserMenu;
