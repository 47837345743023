import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';

import Animation from 'components/Animation';
import OnlyClient from 'components/OnlyClient';

import PopupWrapper from './components/Wrapper';

const Popup = ({
	isOpen,
	children,
	unmountOnExit,
	animation,
	onExited,
	...props
}) => (
	<OnlyClient>
		<Animation
			animation={animation}
			onExited={onExited}
			show={isOpen}
			unmountOnExit={unmountOnExit}
			mountOnEnter={!unmountOnExit}
		>
			<PopupWrapper {...props}>{children}</PopupWrapper>
		</Animation>
	</OnlyClient>
);

Popup.propTypes = {
	isOpen: PropTypes.bool,
	children: PropTypes.node,
	animation: PropTypes.string,
	unmountOnExit: PropTypes.bool,
	title: PropTypes.string,
	onExited: PropTypes.func,
	isCloseButton: PropTypes.bool
};

Popup.defaultProps = {
	isOpen: true,
	animation: 'fade',
	unmountOnExit: true,
	onExited: _.noop,
	isCloseButton: false
};

export default Popup;
