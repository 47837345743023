import { useEffect } from 'react';
import NProgress from 'nprogress';

const DataLoader = () => {
	useEffect(() => {
		NProgress.start();

		return () => NProgress.done();
	}, []);

	return null;
};

export default DataLoader;
