import { useState, useEffect } from 'react';

const OnlyClient = ({ children }) => {
	const [isComponentMounted, setIsComponentMounted] = useState(false);

	useEffect(() => {
		setIsComponentMounted(true);
	}, []);

	return isComponentMounted ? children : null;
};

export default OnlyClient;
