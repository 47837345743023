import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const ProgressBar = ({ contentRef }) => {
	const [width, setWidth] = useState(0);

	const setProgressBarWidth = () => {
		const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

		if (contentRef.current) {
			const contentOffsetTop = contentRef.current?.offsetTop;
			const contentHeight = contentRef.current?.clientHeight;

			if (scrollTop - contentOffsetTop <= 0) {
				return setWidth(0);
			}

			const spaceToScrollAfterContent =
				scrollHeight - contentHeight - contentOffsetTop;
			const spaceToScrollToContentEnd =
				scrollHeight - scrollTop - spaceToScrollAfterContent;
			const scrollToEnd = Math.abs(scrollHeight - clientHeight - scrollTop);
			const leftSpaceAfterScrollEnded =
				spaceToScrollToContentEnd > scrollToEnd
					? spaceToScrollToContentEnd - scrollToEnd
					: 0;

			const widthInPercent =
				((scrollTop - contentOffsetTop + leftSpaceAfterScrollEnded) /
					contentHeight) *
				100;
			setWidth(widthInPercent >= 100 ? 100 : widthInPercent);
		}
	};

	useEffect(() => {
		const resizeObserver = new ResizeObserver(setProgressBarWidth);
		resizeObserver.observe(contentRef.current);

		window.addEventListener('scroll', setProgressBarWidth);
		return () => {
			window.removeEventListener('scroll', setProgressBarWidth);
			resizeObserver.disconnect();
		};
	}, []);

	if (width === 0) {
		return null;
	}

	return (
		<div className={styles.ProgressBar}>
			<div
				className={styles.ProgressBar__Filler}
				style={{ width: `${width}%`, ...(width === 0 && { height: 0 }) }}
			/>
		</div>
	);
};

ProgressBar.propTypes = {
	contentRef: PropTypes.shape({
		current: PropTypes.object
	})
};

export default ProgressBar;
