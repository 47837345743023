export function logInfo(message) {
	// eslint-disable-next-line no-console
	console.log('info', message);
}

export function logError(message) {
	// eslint-disable-next-line no-console
	console.log('error', message);
}

export function logWarning(message) {
	// eslint-disable-next-line no-console
	console.log('warning', message);
}
