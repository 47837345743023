import { useContext, useEffect, useRef } from 'react';

import { GlobalElementsContext } from 'contexts/GlobalElementsContext';

const useGlobalElementRef = (elementName) => {
	const buttonRef = useRef(null);
	const [, setElementRef] = useContext(GlobalElementsContext);

	useEffect(() => {
		setElementRef(elementName, buttonRef);
	}, [buttonRef, setElementRef, elementName]);

	return buttonRef;
};

export default useGlobalElementRef;
