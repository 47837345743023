import _ from 'underscore';
import { useEffect, useMemo, useState } from 'react';

import { SCREEN_BREAKPOINTS } from 'utils/helpers/constants';

export const useImageType = () => {
	const [imageType, setImageType] = useState(null);

	const imageParams = useMemo(
		() => [
			{
				type: 'desktop',
				breakpoint: SCREEN_BREAKPOINTS.tabletMedium
			},
			{
				type: 'tablet',
				breakpoint: SCREEN_BREAKPOINTS.mobileLarge
			},
			{
				type: 'mobile',
				breakpoint: 0
			}
		],
		[]
	);

	useEffect(() => {
		const onResize = _.throttle(() => {
			const { type } = _(imageParams).find(
				(image) => window.innerWidth >= image.breakpoint
			);
			setImageType(type);
		}, 500);

		onResize();

		window.addEventListener('resize', onResize);

		return () => window.removeEventListener('resize', onResize);
	}, []);

	return [imageType, setImageType];
};
