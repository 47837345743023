import { PVI_NO_STYLES_CLASSNAME } from 'utils/helpers/inclusion';

export const iconNames = [
	'360',
	'accessibility-brain',
	'accessibility-ear',
	'accessibility-eye',
	'accessibility-wheelchair',
	'accessible-new',
	'accessible',
	'architecture',
	'arrow-down',
	'arrow-right',
	'arrow-top',
	'audio-backButton',
	'audio-copy',
	'audio-definition',
	'audio-forwardButton',
	'audio-open',
	'audio-pauseIcon',
	'audio-playIcon',
	'audio-volumeIcon',
	'bookmark',
	'broadcast',
	'calendar-inclusive',
	'calendar-small',
	'calendar',
	'check',
	'chevronBottomRounded',
	'chevronLeft',
	'chevronLeftRounded',
	'chevronRight',
	'chevronRightRounded',
	'cinema',
	'clip',
	'close-bold',
	'close',
	'correct',
	'date',
	'dotsVerticall',
	'download',
	'email',
	'esia',
	'eye',
	'facebook',
	'favorite',
	'filter',
	'hearingProblems',
	'info-circle',
	'info',
	'instagram',
	'lectures',
	'lens',
	'linksQuote',
	'list',
	'literature',
	'location',
	'login',
	'logo',
	'map-pin',
	'media-broadcast',
	'media-events',
	'media-gallery',
	'media-infographic',
	'media-performances',
	'media-tours',
	'mentalDisorders',
	'microphone',
	'museums',
	'music',
	'nav',
	'odnoklassniki',
	'podcast',
	'print',
	'pro',
	'pushkin',
	'question-mark',
	'quote',
	'quoteStart',
	'recommendation',
	'record',
	'round-arrow-down',
	'search',
	'send',
	'share',
	'small-arrow-cursor',
	'socials',
	'star',
	'telegram',
	'theater',
	'ticket',
	'tiktok',
	'traditions',
	'twitter',
	'user',
	'video',
	'virtual-tour',
	'visualImpairment',
	'vkontakte',
	'whatsapp',
	'wheelchair',
	'wheelchairUsers',
	'yaDzen',
	'yaMessenger',
	'youtube'
];

export const defaultPropsSvgCollections = {
	'aria-hidden': 'true',
	className: PVI_NO_STYLES_CLASSNAME,
	focusable: 'false',
	style: { position: 'absolute', zIndex: -1, width: 0, height: 0 },
	xmlns: 'http://www.w3.org/2000/svg',
	xmlnsXlink: 'http://www.w3.org/1999/xlink'
};
