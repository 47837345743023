import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from '@culture/common-helpers/cultureUtils';

import LazyLoadImage from 'components/LazyLoad/Image';

import { FilePropType } from 'propTypes/common';
import { SCREEN_BREAKPOINTS } from 'utils/helpers/constants';

import styles from './styles.module.scss';

const GlobalPromoBlock = ({
	images: { sources, color },
	url,
	showOnDevices,
	title
}) => {
	const [screenSize, setScreenSize] = useState(null);

	useEffect(() => {
		const onResize = () => {
			setScreenSize(() => {
				if (window.innerWidth >= SCREEN_BREAKPOINTS.tabletLarge) {
					return 'desktop';
				}

				if (window.innerWidth >= SCREEN_BREAKPOINTS.mobileMedium) {
					return 'tablet';
				}

				return 'mobile';
			});
		};

		onResize();

		window.addEventListener('resize', onResize);

		return () => window.removeEventListener('resize', onResize);
	}, [sources, showOnDevices]);

	const image = screenSize && sources[screenSize];
	const ratio = image ? (image.height / image.width) * 100 : 17;
	const imageSrc = image && getImageUrl(image);

	return screenSize && showOnDevices[screenSize] ? (
		<a
			href={url}
			className={styles.promoBlock}
			style={{ backgroundColor: `#${color}`, paddingTop: `${ratio}%` }}
		>
			{image && (
				<LazyLoadImage imageSrc={imageSrc}>
					<img className={styles.image} src={imageSrc} alt={title || ''} />
				</LazyLoadImage>
			)}
		</a>
	) : null;
};

GlobalPromoBlock.propTypes = {
	images: PropTypes.shape({
		sources: PropTypes.objectOf(FilePropType).isRequired,
		color: PropTypes.string
	}).isRequired,
	url: PropTypes.string.isRequired,
	showOnDevices: PropTypes.shape({
		desktop: PropTypes.bool.isRequired,
		tablet: PropTypes.bool.isRequired,
		mobile: PropTypes.bool.isRequired
	}).isRequired,
	title: PropTypes.string
};

export default GlobalPromoBlock;
