import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import { useCookies } from 'react-cookie';
import { getImageUrl } from '@culture/utils/helpers/files';
import { addYears } from 'date-fns';
import Image from 'next/image';
import EntityPropTypes from 'propTypes/entities';

import LazyLoadImage from 'components/LazyLoad/Image';
import Icon from 'components/Icon';
import { ImageFormatContext } from 'contexts/ImageFormatContext';

import { useImageType } from '../useImageType';
import styles from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

const TopPromoBlock = ({ entity, nameCookie }) => {
	const [, setCookie] = useCookies([nameCookie]);
	const [imageType] = useImageType(null);
	const {
		title,
		url,
		images: { color, sources }
	} = entity;
	const imageFormat = useContext(ImageFormatContext);

	const imageSrc =
		imageType &&
		getImageUrl(sources[imageType], {
			config: publicRuntimeConfig,
			format: imageFormat,
			params: {
				crop: 'fill',
				gravity: 'center'
			}
		});

	const onClickClose = () => {
		setCookie(nameCookie, 'true', {
			expires: addYears(new Date(), 1),
			path: '/'
		});
	};

	return (
		<div className={styles.topPromoBlock} style={{ background: `#${color}` }}>
			<div className={styles.inner}>
				<button
					type="button"
					className={styles.close}
					title="Закрыть"
					onClick={onClickClose}
				>
					<Icon icon="close" className={styles.icon} />
				</button>
			</div>
			<a
				href={url}
				className={styles.content}
				target="_blank"
				rel="noopener"
				title={title}
			>
				<LazyLoadImage imageSrc={imageSrc}>
					<Image
						className={styles.image}
						src={imageSrc}
						layout="fill"
						priority
					/>
				</LazyLoadImage>
			</a>
		</div>
	);
};

TopPromoBlock.propTypes = {
	entity: EntityPropTypes.incutPromoBlock.isRequired,
	nameCookie: PropTypes.string
};

export default TopPromoBlock;
