import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Microdata from 'components/Microdata';
import NextLink from 'components/NextLink';
import Thumbnail from 'components/Thumbnail';

import Badge from './components/Badge';

import styles from './styles.module.scss';

const BaseCard = ({
	animation,
	attributes,
	badges,
	className,
	cropTextParams,
	date,
	description,
	keepRatio,
	microdata,
	nextLink,
	params,
	placeholderAlign,
	placeholderImage,
	preloadImage,
	price,
	sizeText,
	subTitle,
	theme,
	thumbnailFile,
	title,
	typeWidth,
	url
}) => {
	const [isSupportCSSProperty, setIsSupportCSSProperty] = useState(false);

	const cardCx = cx(
		styles.BaseCard,
		styles[`BaseCard_sizeText_${sizeText}`],
		styles[`BaseCard_theme_${theme}`],
		styles[`BaseCard_type_${typeWidth}`],
		styles[`BaseCard_animation_${animation}`],
		{
			[styles.BaseCard_supportProperty]: isSupportCSSProperty
		},
		className
	);

	const attributesContent =
		attributes && attributes.length ? (
			<div className={styles.BaseCard__Attributes}>
				{attributes.map((item) => (
					<span className={styles.BaseCard__AttributesItem} key={item}>
						{item}
					</span>
				))}
			</div>
		) : null;

	const showBadges = !!badges?.length;

	const showMoreInfo =
		!!description || !!price || showBadges || !!attributesContent;

	useEffect(() => {
		if (window.CSS.registerProperty) {
			setIsSupportCSSProperty(true);
		}
	}, []);

	return (
		<NextLink className={cardCx} href={url} next={nextLink}>
			{microdata && <Microdata>{microdata}</Microdata>}
			<div className={styles.BaseCard__WrapThumbnail}>
				<Thumbnail
					className={styles.BaseCard__Thumbnail}
					file={thumbnailFile}
					params={params}
					keepRatio={keepRatio && theme !== 'centeredPromo'}
					preloadImage={preloadImage}
					edgesWithoutBg={theme === 'centeredPromo'}
					behavior={theme === 'centeredPromo' ? 'image' : 'background'}
					placeholderAlign={placeholderAlign}
					placeholderType={placeholderImage}
					theme={theme === 'centeredPromo' ? 'circle' : null}
				/>
			</div>
			<div className={styles.BaseCard__Content}>
				{date && (
					<div className={styles.BaseCard__Head}>
						<div>{date.dayMonth}</div>
						<div>{date.dayWeek}</div>
					</div>
				)}
				<div className={styles.BaseCard__Body}>
					{subTitle && (
						<div className={styles.BaseCard__Subtitle}>{subTitle}</div>
					)}
					{title && (
						<div
							className={styles.BaseCard__Title}
							style={
								cropTextParams?.title && {
									WebkitLineClamp: cropTextParams?.title
								}
							}
						>
							{title}
						</div>
					)}
					{showMoreInfo && (
						<div className={styles.BaseCard__MoreInfo}>
							{(description || price || !!attributesContent) && (
								<div className={styles.BaseCard__GroupInfo}>
									{attributesContent}
									{description && (
										<div
											className={styles.BaseCard__Description}
											style={
												cropTextParams?.description && {
													WebkitLineClamp: cropTextParams?.description
												}
											}
										>
											{description}
										</div>
									)}
									{price && (
										<div className={styles.BaseCard__Price}>{price}</div>
									)}
								</div>
							)}
							{showBadges && (
								<div className={styles.BaseCard__Badges}>
									{badges.map((badge) => (
										<Badge
											key={badge.icon}
											icon={badge.icon}
											title={badge.title}
										/>
									))}
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</NextLink>
	);
};

BaseCard.propTypes = {
	animation: PropTypes.oneOf(['bottom']),
	attributes: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	),
	badges: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.string,
			title: PropTypes.string
		})
	),
	className: PropTypes.string,
	cropTextParams: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string
	}),
	date: PropTypes.shape({
		dayMonth: PropTypes.string,
		dayWeek: PropTypes.string
	}),
	description: PropTypes.string,
	keepRatio: PropTypes.bool,
	microdata: PropTypes.string,
	nextLink: PropTypes.bool,
	params: PropTypes.shape({
		height: PropTypes.number,
		width: PropTypes.number
	}),
	placeholderAlign: PropTypes.string,
	placeholderImage: PropTypes.string,
	preloadImage: PropTypes.bool,
	price: PropTypes.string,
	sizeText: PropTypes.oneOf(['large']),
	subTitle: PropTypes.string,
	theme: PropTypes.oneOf([
		'centeredPromo',
		'largePromo',
		'navDesktop',
		'person'
	]),
	thumbnailFile: PropTypes.object,
	title: PropTypes.string,
	typeWidth: PropTypes.oneOf([
		'fluidLandscapeSlide',
		'fluidPortraitSlide',
		'fixedLandscape',
		'fixedPortrait',
		'landingLandscape',
		'landingPortrait'
	]),
	url: PropTypes.string.isRequired
};

BaseCard.defaultProps = {
	keepRatio: true,
	params: {}
};

export default BaseCard;
