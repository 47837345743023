import PropTypes from 'prop-types';
import { statusesHash } from '@culture/utils/helpers/statuses';
import {
	DCXType,
	EipskPropType,
	FilePropType,
	GenrePropType,
	LocaleType,
	LocationPropType,
	MainRubricType,
	MaterialPropType,
	MentionedEntitiesPropType,
	MetaPropType,
	RubricPropType,
	TagPropType,
	ThumbnailFilePropType,
	ViewsPropType
} from './common';

export const PromoBlockType = PropTypes.shape({
	title: PropTypes.string.isRequired,
	thumbnailFile: PropTypes.object.isRequired,
	publishDate: PropTypes.string
});

export const PublicationType = PropTypes.shape({
	_id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	thumbnailFile: PropTypes.object,
	author: PropTypes.string,
	createDate: PropTypes.string,
	creator: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
	editor: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
	isEditorsChoice: PropTypes.bool,
	isReadersChoice: PropTypes.bool,
	jsonText: PropTypes.array,
	mainRubric: RubricPropType,
	mentionedEntities: MentionedEntitiesPropType,
	meta: MetaPropType,
	name: PropTypes.string,
	podcasts: PropTypes.array,
	publishDate: PropTypes.string,
	rssFlags: PropTypes.object,
	rubricPaths: PropTypes.arrayOf(PropTypes.number),
	rubrics: PropTypes.arrayOf(RubricPropType),
	shortText: PropTypes.string,
	showDefaultThumbnail: PropTypes.bool,
	similarEntities: PropTypes.array,
	status: PropTypes.string,
	tags: PropTypes.arrayOf(TagPropType),
	text: PropTypes.string,
	type: PropTypes.string,
	updateDate: PropTypes.string,
	views: ViewsPropType,
	wasExportedToSheets: PropTypes.bool
});

export const MovieType = PropTypes.shape({
	title: PropTypes.string.isRequired,
	thumbnailFile: PropTypes.object.isRequired,
	fromDate: PropTypes.string,
	toDate: PropTypes.string,
	type: PropTypes.string,
	year: PropTypes.number,
	duration: PropTypes.number,
	genres: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string
		})
	),
	institute: PropTypes.shape({
		title: PropTypes.string.isRequired
	})
});

export const IncutPromoBlockType = PropTypes.shape({
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	images: PropTypes.shape({
		color: PropTypes.string.isRequired,
		sources: PropTypes.shape({
			desktop: PropTypes.object.isRequired,
			tablet: PropTypes.object.isRequired,
			mobile: PropTypes.object.isRequired
		}).isRequired
	})
});

export const InstituteType = PropTypes.shape({
	_id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	thumbnailFile: PropTypes.object,
	publishDate: PropTypes.string,
	type: PropTypes.string,
	address: PropTypes.string,
	location: LocationPropType
});

export const instituteContacts = PropTypes.shape({
	email: PropTypes.string,
	url: PropTypes.string,
	phones: PropTypes.arrayOf(
		PropTypes.shape({
			comment: PropTypes.string,
			value: PropTypes.string
		})
	),
	socials: PropTypes.shape({
		vkontakte: PropTypes.string,
		facebook: PropTypes.string,
		twitter: PropTypes.string,
		odnoklassniki: PropTypes.string
	})
});

export const SpecialProjectType = PropTypes.shape({
	title: PropTypes.string.isRequired,
	thumbnailFile: PropTypes.object.isRequired,
	alias: PropTypes.string,
	canonicalUrl: PropTypes.string
});

export const PersonType = PropTypes.shape({
	title: PropTypes.string.isRequired,
	thumbnailFile: PropTypes.object
});

export const TagType = PropTypes.shape({
	_id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	status: PropTypes.oneOf(Object.keys(statusesHash)).isRequired
});

export const VirtualTourType = PropTypes.shape({
	title: PropTypes.string.isRequired,
	thumbnailFile: PropTypes.object.isRequired,
	url: PropTypes.string.isRequired,
	federalSubject: PropTypes.shape({
		title: PropTypes.string.isRequired
	})
});

export const BroadcastType = PropTypes.shape({
	title: PropTypes.string.isRequired,
	institute: PropTypes.object,
	publishDate: PropTypes.string.isRequired,
	toDate: PropTypes.string,
	thumbnailFile: PropTypes.object.isRequired
});

export const SeanceType = PropTypes.shape({
	placeIndex: PropTypes.number,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	startTime: PropTypes.number,
	endTime: PropTypes.number,
	localeStartDate: PropTypes.string,
	localeEndDate: PropTypes.string
});

export const PlaceType = PropTypes.shape({
	saleLink: PropTypes.string,
	schedule: PropTypes.array,
	seances: PropTypes.arrayOf(SeanceType),
	location: LocationPropType,
	localeIds: PropTypes.arrayOf(PropTypes.number),
	locale: LocaleType,
	address: PropTypes.string,
	title: PropTypes.string,
	eipskId: PropTypes.number,
	institute: PropTypes.shape({
		_id: PropTypes.number,
		type: PropTypes.string,
		name: PropTypes.string,
		contacts: instituteContacts
	}),
	seanceEndDate: PropTypes.string
});

export const EventType = PropTypes.shape({
	_id: PropTypes.number.isRequired,
	status: PropTypes.string,
	title: PropTypes.string,
	name: PropTypes.string,
	rubrics: PropTypes.arrayOf(RubricPropType),
	mainRubric: MainRubricType,
	meta: MetaPropType,
	views: ViewsPropType,
	isFullView: PropTypes.bool,
	isViewed: PropTypes.bool,
	showInPromo: PropTypes.bool,
	isPremiere: PropTypes.bool,
	saleLink: PropTypes.string,
	forbiddenCountries: PropTypes.array,
	eipsk: PropTypes.shape({
		eventId: PropTypes.number,
		organizationId: PropTypes.number,
		createDate: PropTypes.string
	}),
	isTechnicalEvent: PropTypes.bool,
	isPushkinsCard: PropTypes.bool,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	publishDate: PropTypes.string,
	shortText: PropTypes.string,
	text: PropTypes.string,
	places: PropTypes.arrayOf(PlaceType),
	organizer: PropTypes.string,
	similarEntities: PropTypes.array,
	eipskSourceJson: PropTypes.string,
	isTrustedSource: PropTypes.bool,
	topInCatalog: PropTypes.bool,
	topInPushkinsCatalog: PropTypes.bool,
	roundTheClock: PropTypes.bool,
	permanent: PropTypes.bool,
	ageRestriction: PropTypes.number,
	genres: PropTypes.arrayOf(GenrePropType),
	thumbnailFile: ThumbnailFilePropType,
	materials: PropTypes.array,
	files: PropTypes.arrayOf(FilePropType),
	tags: PropTypes.arrayOf(TagPropType),
	price: PropTypes.shape({
		min: PropTypes.number,
		max: PropTypes.number
	}),
	titleWords: PropTypes.arrayOf(PropTypes.string),
	seances: PropTypes.arrayOf(SeanceType),
	seanceStartDate: PropTypes.string,
	seanceEndDate: PropTypes.string,
	rubricPaths: PropTypes.arrayOf(PropTypes.number),
	subordinationsCount: PropTypes.number,
	mentionedEntities: MentionedEntitiesPropType,
	createDate: PropTypes.string,
	updateDate: PropTypes.string,
	editor: PropTypes.number,
	isEditorsChoice: PropTypes.bool,
	isReadersChoice: PropTypes.bool,
	hideThumbnailAndGallery: PropTypes.bool,
	isMuseumExhibition: PropTypes.bool,
	organizations: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			subordinationIds: PropTypes.arrayOf(PropTypes.number),
			organizationId: PropTypes.number
		})
	),
	nearestDate: PropTypes.string,
	jsonText: PropTypes.array
});

export const ArchitectureTermType = PropTypes.shape({
	title: PropTypes.string.isRequired,
	thumbnailFile: PropTypes.object,
	mainRubric: MainRubricType,
	rubrics: PropTypes.array
});

export const BookType = PropTypes.shape({
	authors: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired,
	thumbnailFile: PropTypes.object
});

export const PoemType = PropTypes.shape({
	title: PropTypes.string.isRequired,
	author: PersonType,
	tags: PropTypes.arrayOf(TagType).isRequired,
	text: PropTypes.string.isRequired
});
export const ContactsType = PropTypes.shape({
	email: PropTypes.string,
	url: PropTypes.string,
	phone: PropTypes.array
});

export const ReviewType = PropTypes.shape({
	_id: PropTypes.number.isRequired,
	createDate: PropTypes.string,
	eipsk: EipskPropType,
	eipskSourceJson: PropTypes.string,
	event: EventType,
	files: PropTypes.array,
	genres: PropTypes.array,
	jsonText: PropTypes.array,
	locale: LocaleType,
	mainRubric: MainRubricType,
	materials: PropTypes.array,
	mentionedEntities: PropTypes.object,
	name: PropTypes.string,
	organizationName: PropTypes.string,
	publishDate: PropTypes.string,
	rubrics: PropTypes.arrayOf(RubricPropType),
	similarEntities: PropTypes.array,
	status: PropTypes.string.isRequired,
	tags: PropTypes.arrayOf(TagPropType),
	text: PropTypes.string,
	thumbnailFile: PropTypes.object,
	title: PropTypes.string.isRequired,
	updateDate: PropTypes.string,
	views: ViewsPropType
});

export const CollectiveType = PropTypes.shape({
	_id: PropTypes.number.isRequired,
	status: PropTypes.string,
	title: PropTypes.string.isRequired,
	shortTitle: PropTypes.string,
	name: PropTypes.string,
	meta: MetaPropType,
	views: ViewsPropType,
	isViewed: PropTypes.bool,
	eipsk: EipskPropType,
	eipskSourceJson: PropTypes.string,
	publishDate: PropTypes.string,
	shortText: PropTypes.string,
	text: PropTypes.string,
	director: PropTypes.string,
	address: PropTypes.string,
	participants: PropTypes.array,
	materials: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.number,
			title: PropTypes.string,
			type: PropTypes.string
		})
	),
	location: LocationPropType,
	similarEntities: PropTypes.array,
	passport: PropTypes.shape({
		kind: PropTypes.string,
		organization: PropTypes.string
	}),
	contacts: instituteContacts,
	mainRubric: MainRubricType,
	rubrics: PropTypes.arrayOf(RubricPropType),
	tags: PropTypes.arrayOf(TagPropType),
	thumbnailFile: ThumbnailFilePropType,
	addressComment: PropTypes.string,
	rubricPaths: PropTypes.arrayOf(PropTypes.number),
	mentionedEntities: MentionedEntitiesPropType,
	createDate: PropTypes.string,
	updateDate: PropTypes.string,
	city: PropTypes.shape({
		_id: PropTypes.number,
		title: PropTypes.string
	}),
	federalSubject: PropTypes.shape({
		_id: PropTypes.number,
		title: PropTypes.string
	}),
	dcx: DCXType,
	type: PropTypes.string,
	isEditorsChoice: PropTypes.bool,
	isReadersChoice: PropTypes.bool,
	creator: PropTypes.number,
	editor: PropTypes.number,
	titleWords: PropTypes.arrayOf(PropTypes.string),
	jsonText: PropTypes.array
});

export const TouristRouteType = PropTypes.shape({
	_id: PropTypes.number.isRequired,
	title: PropTypes.string,
	shortTitle: PropTypes.string,
	subTitle: PropTypes.string,
	tags: PropTypes.arrayOf(PropTypes.string),
	rubrics: PropTypes.arrayOf(RubricPropType),
	shortText: PropTypes.string,
	text: PropTypes.string,
	locales: PropTypes.array,
	federalSubjects: PropTypes.array,
	location: LocationPropType,
	bottomPromoBlocks: PropTypes.array,
	items: PropTypes.arrayOf(PropTypes.object),
	meta: MetaPropType,
	publishDate: PropTypes.string,
	creator: PropTypes.number,
	status: PropTypes.string,
	author: PropTypes.string,
	similarEntities: PropTypes.array,
	editor: PropTypes.number,
	name: PropTypes.string,
	rubricPaths: PropTypes.arrayOf(PropTypes.number),
	views: ViewsPropType,
	createDate: PropTypes.string,
	updateDate: PropTypes.string,
	thumbnailFile: ThumbnailFilePropType,
	titleWords: PropTypes.arrayOf(PropTypes.string),
	jsonText: PropTypes.array
});

export const ObjectType = PropTypes.shape({
	_id: PropTypes.number,
	status: PropTypes.string,
	createDate: PropTypes.string,
	publishDate: PropTypes.string,
	updateDate: PropTypes.string,
	creator: PropTypes.number,
	editor: PropTypes.number,
	thumbnailFile: ThumbnailFilePropType,
	mainRubric: MainRubricType,
	rubrics: PropTypes.arrayOf(RubricPropType),
	rubricPaths: PropTypes.arrayOf(PropTypes.number),
	meta: MetaPropType,
	title: PropTypes.string,
	shortTitle: PropTypes.string,
	name: PropTypes.string,
	shortText: PropTypes.string,
	text: PropTypes.string,
	federalSubjects: PropTypes.array,
	materials: PropTypes.arrayOf(MaterialPropType),
	tags: PropTypes.arrayOf(TagPropType),
	cities: PropTypes.array,
	address: PropTypes.string,
	owner: PropTypes.string,
	useRights: PropTypes.string,
	religion: PropTypes.string,
	language: PropTypes.string,
	views: ViewsPropType,
	isEditorsChoice: PropTypes.bool,
	isMajor: PropTypes.bool,
	type: PropTypes.string,
	postIndex: PropTypes.string,
	location: LocationPropType,
	sourceText: PropTypes.string,
	isVerified: PropTypes.bool,
	relatedInstitutes: PropTypes.array,
	ethnics: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.number,
			status: PropTypes.string,
			createDate: PropTypes.string,
			publishDate: PropTypes.string,
			updateDate: PropTypes.string,
			title: PropTypes.string,
			shortText: PropTypes.string,
			text: PropTypes.string,
			views: PropTypes.shape({
				total: PropTypes.number,
				week: PropTypes.number,
				month: PropTypes.number,
				totalCorrection: PropTypes.number
			}),
			federalSubjects: PropTypes.array,
			tags: PropTypes.array,
			sourceData: PropTypes.shape({
				id: PropTypes.number,
				title: PropTypes.string,
				description: PropTypes.any,
				text: PropTypes.any,
				thumbnail_id: PropTypes.any,
				status_id: PropTypes.number,
				author_id: PropTypes.any,
				editor_id: PropTypes.any,
				created_at: PropTypes.string,
				modified_at: PropTypes.string,
				published_at: PropTypes.any,
				views_total: PropTypes.number,
				views_by_week: PropTypes.number,
				views_by_month: PropTypes.number
			}),
			name: PropTypes.string
		})
	),
	egrkn: PropTypes.shape({
		uid: PropTypes.string,
		title: PropTypes.string
	}),
	gk: PropTypes.shape({
		no: PropTypes.string,
		date: PropTypes.string
	}),
	dcx: DCXType,
	similarEntities: PropTypes.array,
	passport: PropTypes.shape({
		confession: PropTypes.string,
		category: PropTypes.string,
		descriptionAuthor: PropTypes.string,
		expedition: PropTypes.string,
		historyFixation: PropTypes.string,
		bibliography: PropTypes.string,
		discography: PropTypes.string,
		buildingDateStart: PropTypes.string,
		buildingDateEnd: PropTypes.string,
		preservation: PropTypes.string,
		architectureStyle: PropTypes.string,
		filmography: PropTypes.string
	}),
	mentionedEntities: MentionedEntitiesPropType,
	locales: PropTypes.array,
	titleWords: PropTypes.arrayOf(PropTypes.string),
	jsonText: PropTypes.array
});

export default {
	event: EventType,
	incutPromoBlock: IncutPromoBlockType,
	institute: InstituteType,
	movie: MovieType,
	person: PersonType,
	promoBlock: PromoBlockType,
	publication: PublicationType,
	specialProject: SpecialProjectType,
	tag: TagType,
	virtualTours: VirtualTourType,
	broadcast: BroadcastType,
	architectureTerm: ArchitectureTermType,
	book: BookType,
	poem: PoemType,
	review: ReviewType,
	collective: CollectiveType,
	object: ObjectType,
	touristRoute: TouristRouteType
};
