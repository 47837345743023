import { useEffect, useState } from 'react';
import { SCREEN_BREAKPOINTS } from '../utils/helpers/constants';

export function useIsMobile() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		setIsMobile(window.innerWidth <= SCREEN_BREAKPOINTS.tabletLarge);

		const onResize = () => {
			setIsMobile(window.innerWidth <= SCREEN_BREAKPOINTS.tabletLarge);
		};

		window.addEventListener('resize', onResize);

		return () => window.removeEventListener('resize', onResize);
	}, []);

	return isMobile;
}
