import _ from 'underscore';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';
import LazyLoadComponent from './index';

const LazyLoadImage = memo(
	({ imageSrc, preloadImage, placeholder, onLoadImage, ...props }) => {
		const [imageLoader, setImageLoader] = useState(null);
		const [loaded, setLoaded] = useState(false);

		const loadImage = useMemo(
			() =>
				_.once(() => {
					const loader = new Image();
					loader.src = imageSrc;

					loader.onload = () => {
						setLoaded(true);
						setImageLoader(null);

						if (onLoadImage) {
							onLoadImage(imageSrc);
						}
					};

					setImageLoader(loader);
				}),
			[imageSrc]
		);

		useEffect(
			() => () => {
				if (imageLoader) {
					imageLoader.onload = _.noop;
				}
			},
			[imageLoader]
		);

		useEffect(() => {
			if (preloadImage) {
				const timerHandle = setTimeout(loadImage, 500);

				return () => {
					clearTimeout(timerHandle);
				};
			}

			return undefined;
		}, [preloadImage, loadImage]);

		const onChange = useCallback(
			(visibility) => {
				if (visibility && !loaded) {
					loadImage();
				}
			},
			[loaded, loadImage]
		);

		const basePlaceholder = placeholder || (
			<span data-cy="default-placeholder" className={styles.image} />
		);

		return imageSrc ? (
			<LazyLoadComponent
				placeholder={
					<>
						{basePlaceholder}
						<noscript>{props.children}</noscript>
					</>
				}
				onChange={onChange}
				canShow={loaded}
				animate={!preloadImage}
				{...props}
			/>
		) : (
			<span data-cy="ordinary-image-placeholder">{basePlaceholder}</span>
		);
	}
);

LazyLoadImage.propTypes = {
	imageSrc: PropTypes.string,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	preloadImage: PropTypes.bool,
	placeholder: PropTypes.node,
	onLoadImage: PropTypes.func
};

LazyLoadImage.defaultProps = {
	preloadImage: false
};

export default LazyLoadImage;
