import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'components/Icon';

import styles from './styles.module.scss';

const UserButton = ({
	title,
	icon,
	theme,
	active,
	onClick,
	toggle,
	hasNotify,
	getRef,
	className
}) => {
	const userButtonCx = cx(styles.button, className, styles[`theme_${theme}`], {
		[styles.active]: active,
		[styles.toggle]: toggle,
		[styles.hasNotify]: hasNotify
	});

	return (
		<button
			className={userButtonCx}
			type="button"
			title={title}
			onClick={onClick}
			ref={getRef}
		>
			<span className={styles.primary}>
				<Icon icon={icon} className={cx(styles.icon, styles[icon])} />
			</span>
			{toggle && theme !== 'bright' && (
				<span className={styles.secondary}>
					<Icon icon="close" className={cx(styles.icon, styles.close)} />
				</span>
			)}
		</button>
	);
};

UserButton.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	theme: PropTypes.oneOf(['bright']),
	active: PropTypes.bool,
	onClick: PropTypes.func,
	getRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	toggle: PropTypes.bool,
	hasNotify: PropTypes.bool,
	className: PropTypes.string
};

UserButton.defaultProps = {
	theme: null,
	active: false,
	toggle: false,
	onClick: _.noop,
	getRef: null,
	hasNotify: false
};

export default UserButton;
