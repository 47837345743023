import React from 'react';

import styles from './styles.module.scss';

const Copyright = () => {
	const date = new Date();
	const currentYear = date.getFullYear();

	return (
		<div className={styles.copyright}>
			&copy; 2013–{currentYear} ФКУ «Цифровая культура». Все права защищены
		</div>
	);
};

export default Copyright;
