import filesHelpers from '@culture/utils/helpers/files';
import getConfig from 'next/config';

const config = getConfig();

const getImageUrl = (file, options = {}) =>
	filesHelpers.getImageUrl(file, {
		config: config?.publicRuntimeConfig || {},
		...options
	});

export const getPreviewUrl = (basePreview) => {
	const basePath = filesHelpers.getBasePath({
		config: config?.publicRuntimeConfig.services.video
	});

	const basePreviewWithoutSlash =
		basePreview[0] === '/' ? basePreview.slice(1) : basePreview;

	return `${basePath}/${basePreviewWithoutSlash}`;
};

export default { getImageUrl };
