import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SocialLinksButton from './button';

import styles from './styles.module.scss';

const SocialLinks = ({ data, theme, spacing }) => {
	const socialLinksCx = cx(
		styles.SocialLinks,
		styles[`SocialLinks_theme_${theme}`],
		styles[`SocialLinks_spacing_${spacing}`]
	);

	return (
		<div className={socialLinksCx}>
			{_(data).map((item) => (
				<SocialLinksButton item={item} theme={theme} key={item.icon} />
			))}
		</div>
	);
};

SocialLinks.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
			icon: PropTypes.string.isRequired
		})
	).isRequired,
	theme: PropTypes.oneOf(['bright']),
	spacing: PropTypes.oneOf(['middle'])
};

export default SocialLinks;
