import React from 'react';
import getConfig from 'next/config';

import dynamic from 'next/dynamic';

import styles from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

const Analytics = dynamic(() => import('../Analytics'), { ssr: false });

const AdditionalAboutInfo = () => (
	<div>
		<div className={styles.block}>
			<div className={styles.policy}>
				При цитировании и копировании материалов с портала активная гиперссылка
				обязательна
			</div>
		</div>
		<div className={styles.block}>
			{publicRuntimeConfig.features.analytics && (
				<Analytics services={['yandex', 'rambler', 'pixel']} />
			)}
		</div>
	</div>
);

export default AdditionalAboutInfo;
