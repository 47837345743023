import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Block = ({ title, children }) => (
	<div className={styles.block}>
		<div className={styles.head}>{title}</div>
		<div className={styles.body}>{children}</div>
	</div>
);

Block.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default Block;
