import _ from 'underscore';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { NavigationPropType } from 'propTypes/common';

import NavigationItem from './components/Item';

import styles from './styles.module.scss';

const Navigation = ({ data, showActiveItem, onSetActiveItem, theme, type }) => {
	const [activeItemId, setActiveItemId] = useState(null);
	const [activeItemRef, setActiveItemRef] = useState({});

	useEffect(() => {
		if (!showActiveItem) {
			setActiveItemId(null);
			setActiveItemRef({});
		}
	}, [showActiveItem]);

	const setActiveItem = useCallback(
		(_id, ref) => {
			const itemId = activeItemId === _id ? null : _id;
			const itemRef = itemId ? ref : {};

			setActiveItemId(itemId);
			setActiveItemRef(itemRef);

			if (itemId && onSetActiveItem) {
				onSetActiveItem(itemRef, activeItemRef);
			}
		},
		[activeItemId, activeItemRef]
	);

	const onMouseEnter = useCallback(
		(_id, ref) => {
			if (type !== 'mobile') {
				setActiveItem(_id, ref);
			}
		},
		[activeItemId, type]
	);

	const onMouseLeave = useCallback(() => {
		if (type !== 'mobile') {
			setActiveItemId(null);
			setActiveItemRef({});
		}
	}, [type]);

	const onClick = useCallback(
		(_id, ref) => {
			if (type === 'mobile') {
				setActiveItem(_id, ref);
			}
		},
		[setActiveItem, type]
	);

	const navigationCx = cx(
		styles.nav,
		styles[`theme_${theme}`],
		styles[`type_${type}`]
	);

	return (
		<nav className={navigationCx}>
			<ul className={styles.list}>
				{_(data).map((item) => (
					<NavigationItem
						key={item._id}
						item={item}
						isOpen={item._id === activeItemId}
						type={type}
						onMouseEnterItem={onMouseEnter}
						onMouseLeaveItem={onMouseLeave}
						onClickButton={onClick}
						theme={item.name}
						className={styles.item}
						linkClassName={styles.link}
					/>
				))}
			</ul>
		</nav>
	);
};

Navigation.propTypes = {
	data: NavigationPropType.isRequired,
	showActiveItem: PropTypes.bool,
	onSetActiveItem: PropTypes.func,
	theme: PropTypes.oneOf(['main', 'footer']),
	type: PropTypes.oneOf(['mobile'])
};

Navigation.defaultProps = {
	showActiveItem: true
};

export default Navigation;
