export const navigationLinks = {
	userMenu: [
		{
			_id: 2,
			title: 'Профиль',
			url: '/profile'
		},
		{
			_id: 3,
			title: 'Избранное',
			url: '/profile/favorites'
		},
		// {
		// 	_id: 4,
		// 	title: 'Подписки',
		// 	url: '/profile/subscribe'
		// },
		{
			_id: 5,
			title: 'Результаты',
			url: '/profile/results'
		},
		{
			_id: 6,
			title: 'Интересы',
			url: '/profile/interests'
		},
		{
			_id: 7,
			title: 'Выйти',
			url: '/auth/logout'
		}
	],
	footer: [
		{
			_id: 1,
			title: 'Афиша',
			url: '/afisha'
		},
		{
			_id: 2,
			title: 'Live',
			url: '/live'
		},
		{
			_id: 3,
			title: 'Спецпроекты',
			url: '/s'
		},
		{
			_id: 4,
			title: 'Кино',
			url: '/cinema'
		},
		{
			_id: 5,
			title: 'Музеи',
			url: '/museums'
		},
		{
			_id: 6,
			title: 'Музыка',
			url: '/music'
		},
		{
			_id: 7,
			title: 'Театр',
			url: '/theaters'
		},
		{
			_id: 9,
			title: 'Литература',
			url: '/literature'
		},
		{
			_id: 10,
			title: 'Традиции',
			url: '/traditions'
		},
		{
			_id: 8,
			title: 'Архитектура',
			url: '/architecture'
		},
		{
			_id: 11,
			title: 'Образование',
			url: '/lectures'
		}
	],
	about: [
		{
			title: 'О\u00A0проекте',
			url:
				'/materials/74852/portal-kulturnogo-naslediya-i-traditsiy-rossii-kultura-rf'
		},
		{
			title: 'Открытые данные',
			url: 'http://opendata.mkrf.ru/',
			target: '_blank',
			rel: 'noopener'
		}
	]
};
