import _ from 'underscore';
import {
	clearAllBodyScrollLocks,
	disableBodyScroll,
	enableBodyScroll
} from 'body-scroll-lock';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import PropTypes from 'prop-types';

import { GlobalElementsContext } from './GlobalElementsContext';

const BodyScrollLockContext = createContext([false, _.noop]);

const BodyScrollLockProvider = (props) => {
	const [scrollElements, setScrollElements] = useState([]);
	const [getGlobalElements] = useContext(GlobalElementsContext);

	const toggleBodyScrollLock = useCallback((disable, element) => {
		if (disable) {
			disableBodyScroll(element);
		} else {
			enableBodyScroll(element);
		}
	}, []);

	const toggleScrollMargin = useCallback(
		(disableScroll) => {
			const [layout, toTopButton] = getGlobalElements('layout', 'toTopButton');

			if (disableScroll) {
				const paddingRight = window.innerWidth - document.body.offsetWidth;

				layout?.style.setProperty('padding-right', `${paddingRight}px`);
				toTopButton?.style.setProperty('padding-right', `${paddingRight}px`);
			} else {
				layout?.style.removeProperty('padding-right');
				toTopButton?.style.removeProperty('padding-right');
			}
		},
		[getGlobalElements]
	);

	const toggleBodyScrollLockValue = useCallback(
		(scrollElement, disableScroll) => {
			setScrollElements((prevScrollElements) => {
				let newScrollElements = _(prevScrollElements).filter(
					(element) => !element.isSameNode(scrollElement)
				);

				if (disableScroll) {
					newScrollElements = [...newScrollElements, scrollElement];
				}

				if (
					Boolean(newScrollElements.length) !==
					Boolean(prevScrollElements.length)
				) {
					toggleScrollMargin(Boolean(newScrollElements.length));
				}

				toggleBodyScrollLock(disableScroll, scrollElement);

				return newScrollElements;
			});
		},
		[]
	);

	useEffect(
		() => () => {
			clearAllBodyScrollLocks();
			toggleScrollMargin(false);
		},
		[]
	);

	return (
		<BodyScrollLockContext.Provider
			value={[Boolean(scrollElements.length), toggleBodyScrollLockValue]}
		>
			{props.children}
		</BodyScrollLockContext.Provider>
	);
};

BodyScrollLockProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export { BodyScrollLockContext, BodyScrollLockProvider };
