import React from 'react';
import { socialsList } from 'utils/helpers/socials';

import SocialLinks from 'components/SocialLinks';

import Copyright from '../Copyright';

import styles from './styles.module.scss';

const FooterAdditional = () => (
	<div className={styles.footerAddition}>
		<div className={styles.block}>
			<Copyright />
		</div>
		<div className={styles.block}>
			<SocialLinks data={socialsList} />
		</div>
	</div>
);

export default FooterAdditional;
