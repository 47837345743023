import { memo } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

const Microdata = ({ children }) =>
	parse(`<script type='application/ld+json'>${children}</script>`);

Microdata.propTypes = {
	children: PropTypes.node.isRequired
};

export default memo(Microdata);
