import React from 'react';
import Link from 'next/link';

import Icon from 'components/Icon';

import styles from './styles.module.scss';

const Logo = () => (
	<div className={styles.logo}>
		<Link href="/">
			<>
				<a href="/" className={styles.link} title="Логотип Культура.рф">
					<Icon icon="logo" className={styles.icon} />
				</a>
			</>
		</Link>
	</div>
);

export default Logo;
