import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { addYears } from 'date-fns';

import Animation from 'components/Animation';
import Button from 'components/Button';

import styles from './styles.module.scss';

const CookieNotify = () => {
	const [{ cookieUsageDisclaimer }, setCookie] = useCookies([
		'cookieUsageDisclaimer'
	]);
	const [notifyIsOpen, setNotifyIsOpen] = useState(false);

	useEffect(() => {
		setNotifyIsOpen(cookieUsageDisclaimer !== true);
	}, [cookieUsageDisclaimer]);

	const onClose = useCallback(() => {
		setCookie('cookieUsageDisclaimer', 'true', {
			expires: addYears(new Date(), 1),
			path: '/'
		});
	}, []);

	return (
		<Animation animation="verticalSlide" show={notifyIsOpen}>
			<div className={styles.CookieNotify}>
				<div className={styles.CookieNotify__Title}>Мы используем сookie</div>
				<div className={styles.CookieNotify__Content}>
					<div className={styles.CookieNotify__Text}>
						Во время посещения сайта «Культура.РФ» вы соглашаетесь с тем, что мы
						обрабатываем ваши персональные данные с использованием метрических
						программ.&nbsp;
						<a className={styles.CookieNotify__More} href="/cookies">
							Подробнее
						</a>
					</div>
					<Button title="Согласен" onClick={onClose} />
				</div>
			</div>
		</Animation>
	);
};

export default CookieNotify;
