import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import getConfig from 'next/config';
import { getOgMetas } from '@culture/common-helpers/metas/og';
import { EntityPropType, MetaPropType, RubricPropType } from 'propTypes/common';

const { publicRuntimeConfig } = getConfig();

const Meta = ({ meta, rubric, entity, entityType }) => {
	const ogMetas = getOgMetas({
		rubric,
		meta,
		entity,
		entityType,
		config: publicRuntimeConfig
	});

	const title = meta.title || ogMetas.title;
	const description = meta.description || ogMetas.description;

	return (
		<Head>
			<meta name="description" content={description} />

			{meta.keywords && <meta name="keywords" content={meta.keywords} />}

			<meta property="og:url" content={ogMetas.url} />
			<meta property="og:type" content={ogMetas.type} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />

			<meta property="twitter:card" content="summary" />
			<meta property="twitter:title" content={title} />
			<meta
				property="twitter:creator"
				content={publicRuntimeConfig.socials.twitter.creator}
			/>
			<meta
				property="fb:app_id"
				content={publicRuntimeConfig.socials.fb.clientID}
			/>
			{ogMetas.image && <meta property="og:image" content={ogMetas.image} />}

			{ogMetas.imageHeight && (
				<meta property="og:image:height" content={ogMetas.imageHeight} />
			)}

			{ogMetas.imageWidth && (
				<meta property="og:image:width" content={ogMetas.imageWidth} />
			)}
			{ogMetas.siteName && (
				<meta property="og:site_name" content={ogMetas.siteName} />
			)}
		</Head>
	);
};

Meta.propTypes = {
	meta: MetaPropType,
	rubric: RubricPropType,
	entity: EntityPropType,
	entityType: PropTypes.string
};

Meta.defaultProps = {
	meta: null,
	rubric: null,
	entity: null,
	entityType: null
};

export default Meta;
